@import 'PepsiFonts';

.PepsiVideo_Ad_830x300 {
  font-family: 'SofiaW01';
  font-weight: normal;
  box-sizing: border-box;
  width: 830px;
  height: 300px;
  background: #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: url('../../../../public/images/PepsiVideo_Ad_830x300-bg.png')
    no-repeat;

  .videoContainer {
    width: 480px;
    height: 270px;
    video {
      width: 100%;
    }
  }

  .adContent {
    width: 305px;
    height: 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .pepsiLogo {
  }

  .divider {
    height: 100%;
    width: 2px;
    background-color: #fff;
  }

  .main-tagline,
  .team-tagline {
    text-transform: uppercase;
    color: #fff;
    text-shadow: 0 1px 1px rgba(#000, 0.3);
  }

  .main-tagline {
    font-size: 1.3rem;
    font-weight: normal;
    margin-bottom: 4px;
  }

  .team-tagline {
    font-weight: 900;
    font-size: 1.2rem;
  }

  .boxScore {
    table {
      width: 100%;
      border: 1px solid #fff;
      background-color: lighten(#0062c2, 5%);
      border-collapse: collapse;
      font-size: 0.8rem;
      table-layout: fixed;
      .teamAbr {
        font-weight: 900;
        color: #fff;
      }
      tbody tr {
        border-top: 1px solid #ddd;
        td {
          color: #fff;
        }
      }
      th,
      td {
        padding: 7px 0;
      }
      thead tr {
        background-color: #fff;
        th {
          font-weight: normal;
          color: #000;
        }
      }
    }
  }

  .team {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .team-city {
    $size: 80px;
    width: $size;
    height: $size;

    img {
      width: 100%;
    }
  }

  .logoBlock {
    height: 58px;
  }

  .pepsiContent {
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .gameSummary {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 60px;
    color: #fff;
    text-shadow: 0 1px 1px rgba(#000, 0.3);
    & > div {
      width: calc(100% / 3);
    }

    .scoreSummary {
      .score {
        margin-bottom: 9px;
        font-size: 1.5rem;
      }
      .status {
        text-transform: uppercase;
        color: #e5e5e5;
        font-size: 0.8rem;
      }
    }
  }
}
