.SeatGeekLive_All {
  margin: 0 auto;
  width: 960px;
}

@media (max-width: 960px) {
  .SeatGeekLive_All {
    width: 640px;
  }
}

@media (max-width: 640px) {
  .SeatGeekLive_All {
    width: 300px;
  }
}

.SeatGeekLive_All > div {
  float: left;
  margin: 0 10px 20px;
}
