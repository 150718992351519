@import './fonts';

/* Vars
-------------------------*/

// Sizes
$ad-width: 728px;
$ad-height: 90px;


// Colors
$dark: #1a1b1b;
$black: #000;
$white: #fff;
$btnBg: rgba(#30b830, .6);
$gray: #737373;


// Animation Timing
$globalDelay: 300ms;

$bgFade: 100ms;
$player-foreground: 300ms + $globalDelay;
$player-background: 600ms + $globalDelay;
$gameInfo: 1000ms + $globalDelay;
$ticketInfo: 1000ms + $globalDelay;
$button: 1600ms + $globalDelay;


/* Mixins
-------------------------*/

@mixin stacker {
  position: absolute;
  top: 0;
  left: 0;
}


/* Dynamic Backgrounds
-------------------------*/

/* Layout
-------------------------*/

.CanucksSize_728x90 {
  .container {
    text-align: left;
    box-sizing: border-box;
    background-color: $dark;
    font-family: 'agency-vc-regular';
    display: block;
    border: 1px solid $gray;
    width: $ad-width;
    height: $ad-height;
    margin: 0 auto;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/728x90/game_4/dist/images/bg.jpg');
    background-repeat: no-repeat;
    position: relative;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 300ms;
    transition-delay: $bgFade;
    overflow: hidden;
    &.loaded {
      opacity: 1;
    }
  }

  .texture {
    width: $ad-width;
    height: $ad-height;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/728x90/game_4/dist/images/texture.png');
    background-repeat: no-repeat;
    @include stacker;
  }

  .type-texture {
    z-index: 80;
    width: $ad-width;
    height: $ad-height;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/728x90/game_4/dist/images/texture-type.png');
    background-repeat: no-repeat;
    @include stacker;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 500ms;
    transition-delay: $ticketInfo;
    .loaded & {
      opacity: 1;
    }
  }

  .player-frame {
    width: $ad-width;
    height: $ad-height;
    @include stacker;
  }

  .player-foreground {
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/728x90/game_4/dist/images/player-foreground.png');
    background-repeat: no-repeat;
    opacity: 0;
    transform: translateX(-80px);
    .loaded & {
      animation: foregroundAnimation 300ms $player-foreground ease-out forwards;
    }
  }

  @keyframes foregroundAnimation {
    from {
      opacity: 0;
      transform: translateX(-80px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .player-background {
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/728x90/game_4/dist/images/player-background.png');
    background-repeat: no-repeat;
    opacity: 0;
    transform: translateX(50px);
    .loaded & {
      animation: backgroundAnimation 300ms $player-background ease-out forwards;
    }
  }

  @keyframes backgroundAnimation {
    from {
      opacity: 0;
      transform: translateX(50px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .copy-content {
    position: absolute;
    left: 14px;
    top: 9px;
    z-index: 140;
  }


  /* Copy and UI
  -------------------------*/

  .game-info {
    z-index: 100;
    font-family: 'agency-vc-regular';
    text-align-last: auto;
    text-transform: uppercase;
    color: $white;
    // font-size: 2.5em;
    letter-spacing: 0.03rem;
    opacity: 0;
    transform: translateX(-50px);
    // transition-property: opacity;
    // transition-duration: 400ms;
    // transition-delay: $gameInfo;
    .vs {
      font-size: 1.2rem;
    }
    .loaded & {
      animation: game-info-Animation 130ms $gameInfo ease-out forwards;
    }
  }

  @keyframes game-info-Animation {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .team-top {
    font-size: 1.8rem;
  }

  .team-bottom {
    font-size: 1.65rem;
  }

  .date {
    position: relative;
    // top: -7px;
    font-size: 1.53rem;
  }

  .cta-button {
    width: 115px;
    height: 33px;
    line-height: 1.8;
    letter-spacing: .08rem;
    font-size: 1.1rem;
    font-family: 'agency-vc-regular';
    box-sizing: border-box;
    text-align: center;
    background-color: $btnBg;
    color: $white;
    text-transform: uppercase;
    border: 3px solid $white;
    position: absolute;
    right: 11px;
    z-index: 50;
    bottom: 11px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 300ms;
    padding-left: .08rem;
    transition-delay: $button;
    .loaded & {
      opacity: 1;
    }
  }

  .spinner {
    position: absolute;
    top: 13px;
    left: 30px;
  }

  .ticket-info {
    position: relative;
    top: -75px;
    left: 480px;
    color: $white;
    opacity: 0;
    transform: translateX(-20px);
    .loaded & {
      animation: ticket-info-Animation 130ms $ticketInfo ease-out forwards;
    }
  }

  @keyframes ticket-info-Animation {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .hide {
    opacity: 0;
    transition-property: all;
    transition-duration: 300ms;
  }

  .ticket-label {
    text-transform: uppercase;
    letter-spacing: .07rem;
    font-size: .9rem;
  }

  .ticket-price {
    position: relative;
  }

  .dollar-sign {
    position: relative;
    top: -20px;
    font-size: 1.4rem;
    left: 2px;
  }

  .dollar-price {
    font-family: 'agency-vc-bold';
    font-size: 3.9rem;
    top: 3px;
    position: relative;
    letter-spacing: .2rem;
    left: 1px;
  }

  .available-msg {
    text-transform: uppercase;
    font-size: 1.2rem;
    left: -96px;
    // top: 33px;
    position: relative;
  }

}

.CanucksSize_728x90 .loaded {
  .player-background { animation: backgroundAnimation 300ms $player-background ease-out forwards }
  .cta-button,
  .type-texture,
  &.container { opacity: 1 }
  .ticket-info { animation: ticket-info-Animation 130ms $ticketInfo ease-out forwards }
  .game-info { animation: game-info-Animation 130ms $gameInfo ease-out forwards }
  .player-foreground { animation: foregroundAnimation 300ms $player-foreground ease-out forwards }
}
