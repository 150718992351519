/* Vars
-------------------------*/

// Sizes
$ad-width: 300px;
$ad-height: 250px;


// Colors
$dark: #1a1b1b;
$black: #000;
$white: #fff;
$btnBg: rgba(#30b830, .6);
$gray: #737373;


// Animation Timing
$globalDelay: 300ms;

$bgFade: 100ms;
$player-foreground: 300ms + $globalDelay;
$player-background: 600ms + $globalDelay;
$gameInfo: 1000ms + $globalDelay;
$ticketInfo: 1300ms + $globalDelay;
$button: 1600ms + $globalDelay;

@mixin stacker {
  position: absolute;
  top: 0;
  left: 0;
}

/* Layout
-------------------------*/

.canucksLive_2 {

  .container {
    text-align: left;
    box-sizing: border-box;
    background-color: $dark;
    font-family: 'agency-vc-regular';
    font-size: 16px;
    display: block;
    border: 1px solid $gray;
    width: $ad-width;
    height: $ad-height;
    margin: 0 auto;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/300x250/game_3/dist/images/bg.jpg');
    background-repeat: no-repeat;
    position: relative;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 300ms;
    transition-delay: $bgFade;
    overflow: hidden;
  }

  .texture {
    width: $ad-width;
    height: $ad-height;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/300x250/game_3/dist/images/texture.png');
    background-repeat: no-repeat;
    @include stacker;
  }

  .type-texture {
    z-index: 80;
    width: $ad-width;
    height: $ad-height;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/300x250/game_3/dist/images/texture-type.png');
    background-repeat: no-repeat;
    @include stacker;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 500ms;
    transition-delay: 1170ms;
    .canucksLive_1 .loaded & {
      opacity: 1;
    }
  }

  .player-frame {
    width: $ad-width;
    height: $ad-height;
    @include stacker;
  }

  .player-foreground {
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/300x250/game_3/dist/images/player-foreground.png');
    background-repeat: no-repeat;
    opacity: 0;
    transform: translateX(150px);
  }

  @keyframes foregroundAnimation {
    from {
      opacity: 0;
      transform: translateX(150px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .player-background {
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/canucks/300x250/game_3/dist/images/player-background.png');
    background-repeat: no-repeat;
    opacity: 0;
    transform: translateX(-50px);
  }

  @keyframes backgroundAnimation {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .copy-content {
    position: absolute;
    left: 22px;
    top: 15px;
    z-index: 140;
  }


  /* Copy and UI
  -------------------------*/

  .game-info {
    z-index: 100;
    font-family: 'agency-vc-regular';
    text-align-last: auto;
    text-transform: uppercase;
    color: $white;
    font-size: 2.05em;
    letter-spacing: 0.03rem;
    opacity: 0;
    transform: translateX(-50px);
    // transition-property: opacity;
    // transition-duration: 400ms;
    // transition-delay: $gameInfo;
    .vs {
      font-size: 1.2rem;
    }
    // .loaded & {
    //   animation: game-info-Animation 130ms $gameInfo ease-out forwards;
    // }
  }



  @keyframes game-info-Animation {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .team-top {
    font-size: 1.8rem;
  }

  .team-bottom {
    font-size: 2rem;
  }

  .date {
    position: relative;
    top: -4px;
    font-size: 1.5rem;
  }

  .cta-button {
    width: 115px;
    height: 28px;
    line-height: 1.63;
    letter-spacing: .08rem;
    font-size: .9rem;
    font-family: 'agency-vc-regular';
    box-sizing: border-box;
    text-align: center;
    background-color: $btnBg;
    color: $white;
    text-transform: uppercase;
    border: 3px solid $white;
    position: absolute;
    left: 22px;
    z-index: 50;
    bottom: 14px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 300ms;
    transition-delay: $button;
  }

  .spinner {
    position: absolute;
    top: 8px;
    left: 24px;
  }

  .ticket-info {
    position: relative;
    top: 3px;
    color: $white;
    opacity: 0;
    transform: translateX(-20px);
  }

  @keyframes ticket-info-Animation {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .hide {
    opacity: 0;
    transition-property: all;
    transition-duration: 300ms;
  }

  .ticket-label {
    position: absolute;
    top: -3px;
    text-transform: uppercase;
    letter-spacing: .03rem;
  }

  .ticket-price {
    position: relative;
    top: 4px;
  }

  .dollar-sign {
    position: relative;
    top: -3px;
    font-size: 1.4rem;
  }

  .dollar-price {
    top: 18px;
    font-family: 'agency-vc-bold';
    font-size: 3.4rem;
    position: relative;
    left: 3px;
    letter-spacing: .2rem;
  }

  .available-msg {
    left: 0;
    top: 3px;
    font-size: 1.24rem;
    line-height: 1.1;
    position: absolute;
    text-transform: uppercase;
  }

}

.canucksLive_2 .loaded {
  .player-background { animation: backgroundAnimation 300ms $player-background ease-out forwards }
  .cta-button,
  .type-texture,
  &.container { opacity: 1 }
  .ticket-info { animation: ticket-info-Animation 130ms $ticketInfo ease-out forwards }
  .game-info { animation: game-info-Animation 130ms $gameInfo ease-out forwards }
  .player-foreground { animation: foregroundAnimation 300ms $player-foreground ease-out forwards }
}
