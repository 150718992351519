.PGA_Live {
  width: 100%;
  position: relative;
  text-align: center;
}

.phone {
  display: relative;
  margin-top: 20px;
}

.adContainer {
  width: 300px;
  height: 250px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 133px;
  transform: translateX(2px);
}
