.PGA_Ad_Highlights {
  a {
    background: url('../../../../public/images/PGA_highlights-bg.png') no-repeat;
    display: block;
    width: 730px;
    height: 230px;
    padding: 30px;
    overflow: hidden;
    * {
      box-sizing: border-box;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  .videoContainer {
    width: 300px;
    height: auto;
    border: 1px solid #fff;
    video {
      width: 100%;
    }
  }

  .leaderBoard {
    $width: 340px;
    $height: 170px;
    width: $width;
    height: $height;
    background: url('../../../../public/images/PGA_highlights-leaderboard.png')
      no-repeat;
    background-size: $width $height;
  }
}
