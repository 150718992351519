.Pepsi_Video {
  margin: 0 auto;
  width: 960px;
}

@media (max-width: 960px) {
  .Pepsi_Video {
    width: 640px;
  }
}

@media (max-width: 640px) {
  .Pepsi_Video {
    width: 300px;
  }
}

.Pepsi_Video > div {
  float: left;
  margin: 0 10px 20px;
}




.CampaignBuilder {
  width: 100%;
  padding: 0 1.3375rem;
  background: #F2F4F5;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;;
  & * {
    box-sizing: border-box;;
  }

  .Select {
    text-align: left;
    font-size: 0.75rem;
  }
}

.CampaignBuilder-title {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.04rem;
  font-size: 0.9rem;
  color: #3a5161;
}

.CampaignBuilder-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 20px 0 18px;
  &:first-child {
    padding-bottom: 0;
  }
}

.CampaignBuilder-label {
  text-align: left;
  margin-bottom: 8px;
  margin-left: 5px;
  float: left;
  width: 100%;
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.04rem;
  color: #3a5161;
}

.CampaignBuilder-item {
  width: calc(100% / 3);
}

.CampaignBuilder-select {
  padding: 0 .5%;
}
