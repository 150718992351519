.AdsContainer {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.AdsContainer > ul li {
  display: inline-block;
  margin: 0 7px;
}

.AdsContainer > ul li a {
  font-size: 14px;
  text-decoration: none;
  color: lighten(black, 30%);
  border: 2px solid rgba(#35b264, .3);
  padding: 5px 13px;
  border-radius: 5px;
  transition: all 150ms ease-out;
}



.AdsContainer > ul li a.active {
  color: black;
  text-decoration: none;
  background: rgba(#49ba73, .05);
  border: 2px solid rgba(#35b264, .9);
  transition: all 150ms ease-out;
}

.AdsContainer {
  text-align: center;
  ul {
    padding: 25px 0;
  }
}


.AdsContainer_description {
  margin: 15px auto 0;
  max-width: 840px;
  width: 60%;
  p {
    text-align: center;
    font-size: 0.9rem;
    line-height: 1.5;
    color: lighten(#000, 40%);
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    strong {
      color: #000;
    }
  }
  .desc_title {
    // font-weight: bold;
    color: #000;
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
}

.hide,
.showMobile {
  display: none !important;
}

@media (max-width: 640px) {
  .hideMobile {
    display: none !important;
  }

  .showMobile {
    display: inline-block !important;
  }
}
