.CAWrapper {
  width: 960px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  font-family: 'Open Sans', sans-serif;
  font-size: .9rem;
}

.CASelectionInterface {
  margin-top: 30px;
  box-sizing: border-box;
  width: 269px;
  align-self: flex-start;
  background: #F2F4F5;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  padding: 20px 20px 12px 20px;
  .CALabel {
    font-weight: bold;
    font-size: .8rem;
    letter-spacing: .04rem;
    text-transform: uppercase;
    color: #3a5161;
    margin-bottom: 8px;
  }
  .num {
    $size: 15px;
    width: $size;
    display: inline-block;
    line-height: 0;
    background: black;
    color: white;
  }
}

.CASelectionLabel {
  text-transform: uppercase;
  font-size: .8rem;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: .7rem;
  font-weight: 800;
}

.numOuter {
  $size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 8px;
  width: $size;
  height: $size;
  line-height: 10px;
  border: 1px solid darken(#e7e7e7, 15%);
  // background: darken(#e7e7e7, 10%);
  background: #FCFEFF;
  background: #E8E9E9;
}

.numInner {
  font-size: .7rem;
  font-weight: 800;
  color: lighten(black, 20%);
}

.CASelectionInterface-selection {
  margin-bottom: 25px;
}

.CAStageWrapper {
  width: 691px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.CAButtonWrapper {
  margin-bottom: 25px;
}

.CABtn {
  background: #ffffff;
  border: 0;
  outline: 0;
  color: #4d4d4d;
  font-size: .7rem;
  font-weight: 600;
  padding: 5px 13px;
  border: 2px solid rgba(53, 178, 100, 0.3);
  border-right: none;
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:last-child {
    border-right: 2px solid rgba(53, 178, 100, 0.3);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.CAStage {
  position: relative;
  height: 696px;
}

.CASelectionTitle {
  padding: 10px 0;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.1rem;
}

.CASelectionInterface-selection .Select {
  text-align: left;
  font-size: .75rem;
}

.CAStage-300x250-container {
  width: 300px;
  height: 250px;
  top: 133px;
  left: 34px;
  position: absolute;
  background: #e1e1e1;
}
