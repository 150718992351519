@import './../vars';

.header {
  padding: 20px 0;
  background: $white;
  color: $black;
  border-bottom:  2px solid #aee0c1;
  border-bottom:  2px solid #e7e7e7;
  font-family: 'Open Sans', sans-serif;
  h1 {
    display: inline-block;
  }
}

.header-wrapper {
  width: 90%;
  margin: 0 auto;
  @media (max-width: 640px) {
    a svg {
      width: 130px;
    }
  }
}

.header-right-links {
  float: right;
  @media (max-width: 640px) {
    font-size: 0.4rem !important;
  }
}

.header-contact {
  background: #089247;
  margin-top: 5px;
  float: left;
  text-decoration: none;
  padding-bottom: 1px;
  transition: all 140ms ease-out;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  padding: 0.6em 0.75em;
  &:hover {
    background: #115c2f;
  }
  @media (max-width: 640px) {
    display: none;
  }
}

.header-campaigns {
  margin-top: 8px;
  float: left;
  color: $black;
  text-decoration: none;
  padding-bottom: 1px;
  transition: all 140ms ease-out;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.85rem;
  padding: .5em 0 .3em;
  margin-right: 20px;
  border-bottom: 2px solid #c3e8d1;
  &:hover {
    border-bottom-color: #089247;
  }
  @media (max-width: 640px) {
    margin-right: 0;
  }
}
