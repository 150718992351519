.PGA_Ad_Live {
  * {
    box-sizing: border-box;
  }
  .ad_container-300x250 {
    width: 300px;
    height: 250px;
    background: grey;
    display: block;
    display: flex;
    text-decoration: none;
    overflow: hidden;
    padding: 30px 30px 0 112px;
    font-family: 'Roboto Condensed', sans-serif;
    background: url('../../../../public/images/PGA_live-bg.png') no-repeat;
    color: #fff;
    text-shadow: 0px 2px 4px rgba(#000, 0.8);
  }
}

.leaders {
  width: 100%;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transform: translateX(8px);
}

@mixin transition {
  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      transition: all 0.3s #{$i * 0.15}s ease-out;
    }
  }
}

.player {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  opacity: 0;
  // background: red;
  transform: translateY(50px);
  @include transition;
  .loaded & {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes playerLoad {
  from {
    // transform: translateY(-130px);
  }

  to {
    transform: translateY(0);
  }
}

.player--name {
  text-transform: uppercase;
  font-size: 0.9rem;
}

.player--avatar {
  $size: 30px;
  width: $size;
  height: $size;
  img {
    border-radius: 50%;
    width: $size;
    height: $size;
    box-shadow: 0 2px 7px rgba(#000, 0.8);
  }
}

.player--name,
.player--avatar,
.player--rank {
  margin-right: 6px;
}
