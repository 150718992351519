.PGA_countdown {
  * {
    box-sizing: border-box;
  }
  .ad_container-300x250 {
    width: 300px;
    height: 250px;
    background: grey;
    display: block;
    display: flex;
    text-decoration: none;
    overflow: hidden;
    padding: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    background: url('../../../../public/images/PGA_countdown-bg.png') no-repeat;
    border: 1px solid #e2e2e2;
  }
  .countdown--day {
    display: inline-block;
    padding: 4px;
    background-color: #005827;
    color: #faf404;
    font-weight: 400;
  }

  #ticker {
    height: 73px;
    width: 4px;
    background: url('../../../../public/images/PGA_countdown-ticker.png')
      no-repeat;
    position: absolute;
    transform: rotate(0deg);
    transform-origin: 2px 58px;
    top: 71px;
    left: 77px;
  }
}

.countdown {
  text-transform: uppercase;
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
  position: absolute;
  right: 16px;
  font-size: 0.9rem;
  letter-spacing: 0.08rem;
  color: #00511a;
}
