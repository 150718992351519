.DSGLive_All {
  margin: 0 auto;
  width: 960px;
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 960px) {
  .DSGLive_All {
    width: 640px;
  }
}

@media (max-width: 640px) {
  .DSGLive_All {
    width: 300px;
  }
}

.DSGLive_All > div {
  float: left;
  margin: 0 10px 20px;
}
