@font-face {
  font-family: 'dsgFont';
  src: url('https://cdn-akamai.mookie1.com/builds/dsg/nfl_stdBanners/300x250/styles/fonts/DSGSans-Black.otf');
}

@font-face {
  font-family: 'dsgFont2';
  src: url('https://cdn-akamai.mookie1.com/builds/dsg/nfl_stdBanners/300x250/styles/fonts/DIN-Medi.ttf');
}

.DSG_300x250 {
  width: 300px;
  height: 250px;
  position: relative;

  #container {
    left: 0;
    width: 300px;
    height: 250px;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/xaxis/dsg_holiday/300x250/dist/img/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    border: 1px solid #808080;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 0;
    animation-name: fade;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: .5s;
  }

  a {
    color: #fff !important;
  }

  #productTitle {
    text-transform: capitalize;
  }

  #container, #container * {
    position: absolute;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  br {
    display: block !important;
    position: static !important;
  }

  #logo {
    bottom: 43px;
    left: 110px;
    opacity: 0;

    animation-name: fade;
    animation-duration: 1s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: .5s;
  }

  #slide1 {
    top: 26px;
    left: 29px;
    opacity: 0;
    animation-name: fadeInOut;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1s;
  }

  #slide2 {
    top: 55px;
    left: 27px;
    opacity: 0;
    animation-name: fadeInOut;
    animation-duration: 3s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 4.9s;
  }

  #bottom-copy {
    width: 100%;
    text-align: center;
    color: #fff;
    font-family: 'dsgFont', sans-serif;
    font-size: 1.5rem;
    line-height: 1;
    text-transform: uppercase;
    bottom: 7px;
  }

  #slide4 {
    width: 300px;
    height: 250px;
    opacity: 0;
    background: #fff;
    background-image: url('https://fanserv-media.s3.amazonaws.com/client/xaxis/dsg_holiday/300x250/dist/img/slide3bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;

    animation-name: slideIn;
    animation-duration: 1.5s;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
    animation-delay: 7.1s;
  }

  #slide4-logo {
    top: -8px;
    left: 15px;
  }

  #slide4-copy {
    text-align: left;
    width: 100%;
    top: 85px;
    left: 15px;
    color: #000;
    font-family: 'dsgFont2', sans-serif;
    font-size: 1em;
  }

  #slide4-button {
    top: 178px;
    left: 15px;
    cursor: pointer;
  }

  #slide4-jersey {
    top: 13px;
    left: 170px;
  }

  .uppercase {
    text-transform: uppercase;
  }

  @keyframes slideIn {
    0%      { transform: translateX(300px); opacity: 1; }
    100%    { transform: translateX(0px); opacity: 1; }
  }

  @keyframes fade {
    0%      { opacity: 0; }
    100%    { opacity: 1; }
  }

  @keyframes fadeSlide {
    0%      { transform: translateY(10px); opacity: 0; }
    100%    { transform: translateY(0); opacity: 1; }
  }

  @keyframes fadeInOut {
    0%      { transform: translateY(15px); opacity: 0; }
    25%     { transform: translateY(0); opacity: 1; }
    88%     { opacity: 1; }
    100%    { opacity: 0; }
  }

  .SF #slide4-copy,
  .NYG #slide4-copy
  {
    font-size: 0.9em !important;
  }

  .mens_long_sleeve,
  .mens_pullover,
  .womens_long_sleeve,
  .womens_pullover,
  {
    #slide4-copy {
      top: 71px;
      font-size: 0.86rem;
    }
  }
}
