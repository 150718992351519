.BuildPlatform {
  position: relative;
  .platformContainer {
    margin-top: 20px;
    width: 960px;
  }

  .adsContainer {
    .adContainer_830x300 {
      position: absolute;
      &.desktop {
        top: 134px;
        left: 79px;
      }
      &.platform {
        top: 117px;
        left: 118px;
      }
    }
    .adContainer_300x250 {
      position: absolute;
      &.desktop {
        top: 396px;
        right: 50px;
      }
      &.mobile {
        top: 133px;
        left: 332px;
      }
    }
  }
}
