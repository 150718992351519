.PGA_Highlights {
  width: 100%;
  position: relative;
  text-align: center;

  * {
    box-sizing: border-box;
  }

  .browser {
    display: relative;
    margin-top: 20px;
  }

  .adContainer {
    width: 730px;
    height: 230px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 80px;
    transform: translateX(.5px);
  }
}
