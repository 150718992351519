@import './../vars';

.IndexAd-wrapper {
  box-sizing: border-box;
  padding-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  height: 42vh;
  min-height: 298px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid darken($white, 50%);
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: 960px) {
    height: 45vh;
  }
  @media (max-width: 640px) {
    height: 55vh;
  }
}

.IndexAd-desc {
  display: block;
  padding: 20px 0 40px;
  font-size: 0.95rem;
  line-height: 1.5;
  color: darken($white, 18%);
  width: 50%;
  @media (max-width: 640px) {
    width: 90%;
    font-size: 0.8rem;
  }
}

.IndexAd-logoWrapper {
  display: block;
}

.IndexAd-bg-cowboys {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/cowboys_index_bg.jpg');
  background-position: center middle;
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: #002244;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: #002244;
      background: $white;
      border-color: #002244;
    }
  }
}

.IndexAd-bg-pepsi {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/pepsi_index_bg.jpg');
  background-position: center middle;
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: #e32934;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: #e32934;
      background: $white;
      border-color: #e32934;
    }
  }
}

.IndexAd-bg-pga {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/pga_index_bg.jpg');
  background-position: center middle;
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: $color-pga;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: $color-pga;
      background: $white;
      border-color: $color-pga;
    }
  }
}

.IndexAd-bg-pistons {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/pistons_index_bg.jpg');
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: $color-pistons;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: $color-pistons;
      background: $white;
      border-color: $color-pistons;
    }
  }
}

.IndexAd-bg-canucks {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/canucks_index_bg.jpg');
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: $color-canucks;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: $color-canucks;
      background: $white;
      border-color: $color-canucks;
    }
  }
}

.IndexAd-bg-seatgeek {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/seatgeek_index_bg.jpg');
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: $color-seatgeek;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: $color-seatgeek;
      background: $white;
      border-color: $color-seatgeek;
    }
  }
}

.IndexAd-bg-dsg {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/dsg_index_bg.jpg');
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: $color-dsg;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: $color-dsg;
      background: $white;
      border-color: $color-dsg;
    }
  }
}

.hide,
.showMobile {
  display: none !important;
}

@media (max-width: 640px) {
  .hideMobile {
    display: none !important;
  }

  .showMobile {
    display: inline-block !important;
  }
}

.IndexAd-bg-wild {
  background-image: linear-gradient(
      to bottom,
      rgba($black, 0.8) 0%,
      rgba($black, 0.75) 100%
    ),
    url('../../public/images/wild_index_bg.jpg');
  background-size: cover;

  .IndexAd-btn {
    color: $white;
    font-size: 0.8rem;
    border: 1px solid $black;
    padding: 0.75em 1.5em;
    border-radius: 4px;
    background: #024930;
    text-decoration: none;
    transition: all 100ms ease-out;
    &:hover {
      color: #024930;
      background: $white;
      border-color: #024930;
    }
  }
}
